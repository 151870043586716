import dynamic from "next/dynamic";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import classes from "../Navbar.module.scss";

const BrowseNavbarDesktop = dynamic(
  () => import("@components/Navbar/BrowseNavbar/BrowseNavbarDesktop"),
  {
    ssr: false,
  }
);
const BrowseNavbarMobile = dynamic(
  () => import("@components/Navbar/BrowseNavbar/BrowseNavbarMobile"),
  {
    ssr: false,
  }
);

const BrowseNavbar = () => {
  const isTab = useSelector((state) => state.helper.isTab);
  const isMobile = useSelector((state) => state.helper.isMobile);

  return (
    <Fragment>
      <nav className={classes.defaultNav}>
        {isTab || isMobile ? <BrowseNavbarMobile /> : <BrowseNavbarDesktop />}
      </nav>
    </Fragment>
  );
};

export default BrowseNavbar;
