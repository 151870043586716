import { variants } from "@variants";
import PropTypes from "prop-types";
import { Fragment } from "react";
import CourseProgressBar from "./CourseProgressBar";
import JobCourseProgressBar from "./JobCourseProgressBar";
import SkillCourseProgressBar from "./SkillCourseProgressBar";

const StepProgressBar = (props) => {
  const { variant, percent } = props;

  const getProgressBar = (variant) => {
    switch (variant) {
      case variants.courseProgressBar:
        return <CourseProgressBar percent={percent} />;
      case variants.skillCourseProgressBar:
        return <SkillCourseProgressBar percent={percent} />;
      case variants.jobCourseProgressBar:
        return <JobCourseProgressBar percent={percent} />;
      default:
        break;
    }
  };

  return <Fragment>{getProgressBar(variant)}</Fragment>;
};

StepProgressBar.propTypes = {
  percent: PropTypes.number,
  variant: PropTypes.string,
};

StepProgressBar.defaultProps = {
  percent: 0,
  variant: variants.courseProgressBar,
};

export default StepProgressBar;
