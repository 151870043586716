import { ACTION_TYPES } from "./types";
const { SET_COURSE_DETAILS, UPDATE_COURSE_DETAILS } = ACTION_TYPES;

export const setCourseDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_COURSE_DETAILS,
      payload,
    });
  };
};

export const updateCourseDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COURSE_DETAILS,
      payload,
    });
  };
};
