import { ACTION_TYPES } from "./types";
const { SET_SKILL_COURSES_LIST } = ACTION_TYPES;

export const setSkillCourse = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_SKILL_COURSES_LIST,
      payload,
    });
  };
};
