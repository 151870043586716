import { getAuthCookie } from "@helpers/auth";
import { isSSR } from "@helpers/global";
import { decrypt, encrypt } from "@helpers/security";
import {
  COMPLETED_COURSES_STATUS,
  REDUX_PERSIST,
  ROOT_ROUTE,
} from "@localization";
import { isEqual } from "lodash";
let token = null;

export const getCompletedCourseReviewStatus = (slug) => {
  const serializedState = localStorage.getItem(COMPLETED_COURSES_STATUS);
  if (serializedState) {
    const persistedState = JSON.parse(serializedState);
    if (slug) {
      return persistedState.find((course) => course.slug === slug) ?? null;
    }
    return persistedState;
  }
  return null;
};

export const setCourseCompletedReviewStatus = (
  course,
  hasUserCancelledReview = false,
  removeCourseReviewStatus = false
) => {
  const completedCoursesStatus =
    getCompletedCourseReviewStatus(COMPLETED_COURSES_STATUS) ?? [];
  let updatedStatus = [...completedCoursesStatus];
  if (removeCourseReviewStatus) {
    updatedStatus = updatedStatus.filter(
      (courseStatus) => courseStatus.slug !== course.slug
    );
  } else {
    updatedStatus.push({
      slug: course?.slug,
      courseProgress: { ...course?.progress, hasUserCancelledReview },
    });
  }

  localStorage.setItem(COMPLETED_COURSES_STATUS, JSON.stringify(updatedStatus));
};

export const getPersistedAuthToken = () => {
  const serializedState = isSSR() ? null : localStorage.getItem(REDUX_PERSIST);
  if (serializedState) {
    const persistedState = JSON.parse(serializedState);
    if (persistedState?.user?.userDetails?.encryptedAuthToken) {
      token = decrypt(persistedState?.user?.userDetails?.encryptedAuthToken);
    }
  }
  if (!token) {
    getAuthCookie();
  }
  return token;
};

export const getPersistedCurrentUser = () => {
  const serializedState = localStorage.getItem(REDUX_PERSIST);
  let user;
  if (serializedState) {
    const persistedState = JSON.parse(serializedState);
    user = persistedState?.user?.userDetails;
  }
  return user;
};

export const getPersistedLoginStatus = () => {
  const serializedState = localStorage.getItem(REDUX_PERSIST);
  let isUserLoggedIn;
  if (serializedState) {
    const persistedState = JSON.parse(serializedState);
    isUserLoggedIn = persistedState?.user?.isUserLoggedIn;
  }
  return isUserLoggedIn;
};

export const clearReduxPersist = () => {
  localStorage.removeItem(REDUX_PERSIST);
};

export const rePersistAuthenticatedPageData = (
  nextProps,
  reduxCallback,
  origin
) => {
  if (nextProps?.userDetails?.authToken && !getPersistedAuthToken()) {
    const encryptedAuthToken = encrypt(nextProps.userDetails.authToken);
    reduxCallback.userUpdateAction({
      encryptedAuthToken,
    });
  }
  if (nextProps?.userDetails) {
    const persistedUserDetails = getPersistedCurrentUser();
    if (
      !persistedUserDetails ||
      !isEqual(persistedUserDetails, nextProps.userDetails)
    ) {
      reduxCallback.userUpdateAction({
        ...nextProps.userDetails,
      });
    }
  }
  if (!getPersistedLoginStatus() && origin !== ROOT_ROUTE) {
    reduxCallback.userLoginAction();
  }
};

export const getFreeTrialModalClosedStatus = () => {
  return JSON.parse(window.localStorage.getItem("freeTrialModalClosed"));
};

export const setFreeTrialModalClosedStatus = (callback = () => {}) => {
  window.localStorage.setItem("freeTrialModalClosed", true);
  callback();
};

export const removeFreeTrialModalClosedStatus = (callback = () => {}) => {
  window.localStorage.setItem("freeTrialModalClosed", false);
  callback();
};
