import { LockFilled, UnlockFilled } from "@ant-design/icons";
import ProgressSummary from "@components/StepProgressBar/ProgressSummary";
import { Col, Typography } from "antd";
import classNames from "classnames";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import classes from "./StepProgressBar.module.scss";

const CustomIcon = dynamic(() => import("@components/adaptors/CustomIcon"), {
  ssr: false,
});

const { Paragraph } = Typography;

const CourseProgressBar = (props) => {
  const { percent } = props;
  const course = useSelector((state) => state.courseDetails.course);
  const courseProgress = useSelector((state) => state.courseProgress);

  return (
    <Fragment>
      <Col className={classes.stepProgressBarWrapper}>
        <Col className={classes.stepProgressBarArea}>
          <Col className={classes.chapters}>
            {course?.chaptersCompleted}/
            {course?.finalAssessment
              ? course?.chaptersCount + 1
              : course?.chaptersCount}{" "}
            chapters ({percent}%)
          </Col>
          <Col className={classes.stepProgressBar}>
            <ProgressBar
              percent={percent}
              hasStepZero={false}
              filledBackground="#00B58A"
            >
              <Step transition="scale">
                {({ accomplished }) => (
                  <Fragment>
                    <Col
                      className={classNames(
                        classes.stepIndicator,
                        accomplished || courseProgress?.isMinorProjectAccessible
                          ? classes.active
                          : classes.inactive
                      )}
                    >
                      {accomplished ||
                      courseProgress?.isMinorProjectAccessible ? (
                        <UnlockFilled />
                      ) : (
                        <LockFilled />
                      )}
                    </Col>
                    <Paragraph className={classes.stepName}>
                      Minor Project
                    </Paragraph>
                  </Fragment>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished }) => (
                  <Fragment>
                    <Col
                      className={classNames(
                        classes.stepIndicator,
                        accomplished || courseProgress?.isMajorProjectAccessible
                          ? classes.active
                          : classes.inactive
                      )}
                    >
                      {accomplished ||
                      courseProgress?.isMajorProjectAccessible ? (
                        <UnlockFilled />
                      ) : (
                        <LockFilled />
                      )}
                    </Col>
                    <Paragraph className={classes.stepName}>
                      Major Project
                    </Paragraph>
                  </Fragment>
                )}
              </Step>
              <Step transition="scale">
                {({ accomplished }) => (
                  <Fragment>
                    <Col
                      className={classNames(
                        classes.stepIndicator,
                        accomplished || courseProgress?.isFinalAssessmentPassed
                          ? classes.active
                          : classes.inactive
                      )}
                    >
                      {accomplished ||
                      courseProgress?.isFinalAssessmentPassed ? (
                        <UnlockFilled />
                      ) : (
                        <LockFilled />
                      )}
                    </Col>
                    <Paragraph className={classes.stepName}>
                      Certificate
                    </Paragraph>
                  </Fragment>
                )}
              </Step>
            </ProgressBar>
          </Col>
        </Col>
        <ProgressSummary />
      </Col>
    </Fragment>
  );
};

CourseProgressBar.propTypes = {
  percent: PropTypes.number,
  progressSummary: PropTypes.object,
};

CourseProgressBar.defaultProps = {
  percent: 0,
  progressSummary: {},
};

export default CourseProgressBar;
