import { ACTION_TYPES } from "./types";
const { SET_ACTIVE_COURSE_FOOTER_TAB } = ACTION_TYPES;

export const setActiveCourseFooterTab = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_COURSE_FOOTER_TAB,
      payload,
    });
  };
};
