import classes from "./Navbar.module.scss";
import { Row } from "antd";
import Image from "next/image";
import { UNSCHOOL_IMAGE_PATH } from "@localization";

const NeutralNavbar = () => {
  return (
    <Row className={classes.neutralNavbar}>
      <Image
        width="105"
        height="28"
        objectFit="cover"
        className={classes.brandLogo}
        src={UNSCHOOL_IMAGE_PATH}
        alt="Unschool Logo"
      />
    </Row>
  );
};

export default NeutralNavbar;
