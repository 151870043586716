import { ACTION_TYPES } from "./types";
const {
  INCREMENT_ASSESSMENT_QUESTION_PAGE,
  DECREMENT_ASSESSMENT_QUESTION_PAGE,
  SET_ASSESSMENT_QUESTION_PAGE,
  RESET_ASSESSMENT_QUESTION_PAGE,
  SET_ASSESSMENT_TOTAL_PAGES,
  UPDATE_ASSESSMENT_STATE,
  SET_ASSESSMENT_QUESTION_LIST,
  SHOW_ASSESSMENT_MESSAGE,
  CLOSE_ASSESSMENT_MESSAGE,
  SET_ANSWER_RESULT,
  RESET_ANSWER_RESULT,
  SET_QUESTION_TIMEOUT_STATUS,
  SET_CANONICAL_ASSESSMENT,
  SET_ASSESSMENT_FINAL_SCORE,
  RESET_ASSESSMENT,
  SET_ASSESSMENT_FAILED_SUBMISSIONS_COUNT,
} = ACTION_TYPES;

export const incrementQuestionPage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: INCREMENT_ASSESSMENT_QUESTION_PAGE,
      payload,
    });
  };
};

export const decrementQuestionPage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: DECREMENT_ASSESSMENT_QUESTION_PAGE,
      payload,
    });
  };
};

export const setQuestionPage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_QUESTION_PAGE,
      payload,
    });
  };
};

export const resetQuestionPage = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RESET_ASSESSMENT_QUESTION_PAGE,
      payload,
    });
  };
};

export const setTotalQuestionPages = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_TOTAL_PAGES,
      payload,
    });
  };
};

export const updateAssessmentState = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ASSESSMENT_STATE,
      payload,
    });
  };
};

export const setAssessmentQuestionList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_QUESTION_LIST,
      payload,
    });
  };
};

export const showAssessmentMessageAlert = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SHOW_ASSESSMENT_MESSAGE,
      payload,
    });
  };
};

export const closeAssessmentMessageAlert = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CLOSE_ASSESSMENT_MESSAGE,
      payload,
    });
  };
};

export const setAnswerResult = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ANSWER_RESULT,
      payload,
    });
  };
};

export const resetAnswerResult = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RESET_ANSWER_RESULT,
      payload,
    });
  };
};

export const setQuestionTimeoutStatus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_QUESTION_TIMEOUT_STATUS,
      payload,
    });
  };
};

export const setCanonicalAssessment = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CANONICAL_ASSESSMENT,
      payload,
    });
  };
};

export const setAssessmentFinalScore = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_FINAL_SCORE,
      payload,
    });
  };
};

export const resetAssessmentState = (payload) => {
  return (dispatch) => {
    dispatch({
      type: RESET_ASSESSMENT,
      payload,
    });
  };
};

export const setAssessmentFailedSubmissionsCount = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ASSESSMENT_FAILED_SUBMISSIONS_COUNT,
      payload,
    });
  };
};
