import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";

const ProgressSummaryDesktop = dynamic(
  () =>
    import(
      "@components/StepProgressBar/ProgressSummary/ProgressSummaryDesktop"
    ),
  {
    ssr: false,
  }
);
const ProgressSummaryMobile = dynamic(
  () =>
    import("@components/StepProgressBar/ProgressSummary/ProgressSummaryMobile"),
  {
    ssr: false,
  }
);

const ProgressSummary = () => {
  const isMobile = useSelector((state) => state.helper.isMobile);
  return (
    <Fragment>
      {isMobile ? <ProgressSummaryMobile /> : <ProgressSummaryDesktop />}
    </Fragment>
  );
};

ProgressSummary.propTypes = {
  progressInfo: PropTypes.object,
};

ProgressSummary.defaultProps = {
  progressInfo: {},
};

export default ProgressSummary;
