/* eslint-disable react-hooks/exhaustive-deps */
import AuthNavbar from "@components/Navbar/AuthNavbar";
import CourseNavbar from "@components/Navbar/CourseNavbar";
import DefaultNavbar from "@components/Navbar/DefaultNavbar";
import NeutralNavbar from "@components/Navbar/NeutralNavbar";
import { categoryListActions, notificationListActions } from "@redux/actions";
import { variants } from "@variants";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import BrowseNavbar from "./BrowseNavbar";
import JobCourseNavbar from "./JobCourseNavbar";
import SkillCourseNavbar from "./SkillCourseNavbar";

const Navbar = (props) => {
  const { variant, nextProps } = props;
  const dispatch = useDispatch();
  const { setNotificationList, setNotificationPaginationDetails } =
    bindActionCreators(notificationListActions, dispatch);
  const { setCategoryList } = bindActionCreators(categoryListActions, dispatch);

  useEffect(() => {
    setNotificationList(nextProps?.notificationList?.edges);
    setNotificationPaginationDetails(nextProps?.notificationList?.pageInfo);
    setCategoryList(nextProps?.categoryList);
  }, []);

  const getNavbar = () => {
    switch (variant) {
      case variants.authNavbar:
        return <AuthNavbar />;
      case variants.courseNavbar:
        return <CourseNavbar />;
      case variants.skillCourseNavbar:
        return <SkillCourseNavbar />;
      case variants.jobCourseNavbar:
        return <JobCourseNavbar />;
      case variants.neutralNavbar:
        return <NeutralNavbar />;
      case variants.browseNavbar:
        return <BrowseNavbar />;
      default:
        return <DefaultNavbar nextProps={nextProps} />;
    }
  };

  return getNavbar();
};

Navbar.propTypes = {
  variant: PropTypes.string,
};

Navbar.defaultProps = {
  variant: "default",
};

export default Navbar;
