import CustomButton from "@components/adaptors/CustomButton";
import StepProgressBar from "@components/StepProgressBar";
import { fas } from "@icons";
import { COURSES_ROUTE } from "@localization";
import { variants } from "@variants";
import { Col, Typography } from "antd";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import classes from "./Navbar.module.scss";

const { Title } = Typography;

const CourseNavbar = () => {
  const router = useRouter();
  const course = useSelector((state) => state.courseDetails.course);
  const courseProgress = useSelector((state) => state.courseProgress);

  return (
    <Col className={classes.courseNavbar}>
      <Col className={classes.lhs}>
        <CustomButton
          onClick={() => router.push(COURSES_ROUTE)}
          size="small"
          variant={variants.ghostButton}
          iconButton
          icon={fas.faArrowLeft}
        />
        <span className={classes.verticalDivider}></span>
        <Title className={classes.courseTitle}>
          {course?.title}
          {/*<PlanTypes
            variant={variants.basicPlanType}
            customClass={classes.titleImg}
          />*/}
        </Title>
      </Col>
      <Col className={classes.rhs}>
        <StepProgressBar
          variant={variants.courseProgressBar}
          percent={courseProgress?.completionPercentage}
        />
      </Col>
    </Col>
  );
};

export default CourseNavbar;
