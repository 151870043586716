import { ACTION_TYPES } from "./types";
const {
  SET_NOTIFICATIONS_LIST,
  SET_NOTIFICATION_PAGINATION_DETAILS,
  CLEAR_NOTIFICATION_LIST,
} = ACTION_TYPES;

export const setNotificationList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIFICATIONS_LIST,
      payload,
    });
  };
};

export const setNotificationPaginationDetails = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIFICATION_PAGINATION_DETAILS,
      payload,
    });
  };
};

export const clearNotificationList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_NOTIFICATION_LIST,
      payload,
    });
  };
};
