import { ACTION_TYPES } from "./types";
const {
  SET_NOTE_LIST,
  UPDATE_NOTE_LIST,
  UPDATE_NOTE,
  DELETE_NOTE,
  OPEN_ADD_NOTE_ACTION,
  DISCARD_ADD_NOTE_ACTION,
  SET_NOTES_FILTER,
} = ACTION_TYPES;

export const openAddNoteCard = (payload) => {
  return (dispatch) => {
    dispatch({
      type: OPEN_ADD_NOTE_ACTION,
      payload,
    });
  };
};

export const setNotesFilterValue = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTES_FILTER,
      payload,
    });
  };
};

export const discardAddNoteCard = (payload) => {
  return (dispatch) => {
    dispatch({
      type: DISCARD_ADD_NOTE_ACTION,
      payload,
    });
  };
};

export const setNoteList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTE_LIST,
      payload,
    });
  };
};

export const updateNoteList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NOTE_LIST,
      payload,
    });
  };
};

export const updateNote = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_NOTE,
      payload,
    });
  };
};

export const deleteNote = (payload) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_NOTE,
      payload,
    });
  };
};
