import { ACTION_TYPES } from "./types";
const { SET_COURSE_LIST, UPDATE_COURSE_LIST } = ACTION_TYPES;

export const setCourseList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_COURSE_LIST,
      payload,
    });
  };
};

export const updateCourseList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COURSE_LIST,
      payload,
    });
  };
};
