import { ACTION_TYPES } from "./types";
const {
  SET_SYLLABUS_LIST,
  UPDATE_SYLLABUS_CHAPTER,
  UPDATE_SYLLABUS_LESSON,
  UPDATE_SYLLABUS_LIST,
  SET_ACTIVE_LESSON_KEY,
  SET_ACTIVE_CHAPTER_KEY,
} = ACTION_TYPES;

export const setSyllabusList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_SYLLABUS_LIST,
      payload,
    });
  };
};

export const updateSyllabusList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SYLLABUS_LIST,
      payload,
    });
  };
};

export const updateSyllabusChapter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SYLLABUS_CHAPTER,
      payload,
    });
  };
};

export const updateSyllabusLesson = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SYLLABUS_LESSON,
      payload,
    });
  };
};

export const setActiveChapterKey = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_CHAPTER_KEY,
      payload,
    });
  };
};

export const setActiveLessonKey = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_LESSON_KEY,
      payload,
    });
  };
};
