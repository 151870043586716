import { ACTION_TYPES } from "./types";
const { SET_CATEGORY_LIST } = ACTION_TYPES;

export const setCategoryList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_CATEGORY_LIST,
      payload,
    });
  };
};
