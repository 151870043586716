import Navbar from "@components/Navbar";
import { rePersistAuthenticatedPageData } from "@persistance";
import { userActions } from "@redux/actions";
import Layout, { Content } from "antd/lib/layout/layout";
import { NextSeo } from "next-seo";
import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import classes from "./RootLayout.module.scss";

const StonlyWidgetScript = dynamic(
  () => import("@components/StonlyWidgetScript"),
  {
    ssr: false,
  }
);

const RootLayout = (props) => {
  const { children, navbarVariant, origin, nextProps } = props;
  const dispatch = useDispatch();
  const { userUpdateAction, userLoginAction } = bindActionCreators(
    userActions,
    dispatch
  );

  useEffect(() => {
    nextProps &&
      rePersistAuthenticatedPageData(
        nextProps,
        { userLoginAction, userUpdateAction },
        origin
      );
  }, []);

  return (
    <Layout className={classes.rootContainer}>
      <StonlyWidgetScript />
      <NextSeo title="Unschool" />
      <Navbar nextProps={nextProps} variant={navbarVariant} />
      <Content>{children}</Content>
    </Layout>
  );
};

RootLayout.propTypes = {
  navbarVariant: PropTypes.string,
  children: PropTypes.node,
  origin: PropTypes.string,
  nextProps: PropTypes.object,
};

RootLayout.defaultProps = {
  navbarVariant: "",
  children: null,
  origin: "",
  nextProps: {},
};

export default RootLayout;
