import { ACTION_TYPES } from "./types";
const { SET_PROJECT_LIST, UPDATE_PROJECT_LIST, UPDATE_PROJECT } = ACTION_TYPES;

export const setProjectList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_PROJECT_LIST,
      payload,
    });
  };
};

export const updateProjectList = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROJECT_LIST,
      payload,
    });
  };
};

export const updateProject = (payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_PROJECT,
      payload,
    });
  };
};
