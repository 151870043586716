import dynamic from "next/dynamic";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import classes from "../Navbar.module.scss";

const DefaultNavbarDesktop = dynamic(
  () => import("@components/Navbar/DefaultNavbar/DefaultNavbarDesktop"),
  {
    ssr: false,
  }
);
const DefaultNavbarMobile = dynamic(
  () => import("@components/Navbar/DefaultNavbar/DefaultNavbarMobile"),
  {
    ssr: false,
  }
);

const DefaultNavbar = (props) => {
  const { nextProps } = props;
  const isTab = useSelector((state) => state.helper.isTab);
  const isMobile = useSelector((state) => state.helper.isMobile);

  return (
    <Fragment>
      <nav className={classes.defaultNav}>
        {isTab || isMobile ? (
          <DefaultNavbarMobile nextProps={nextProps} />
        ) : (
          <DefaultNavbarDesktop nextProps={nextProps} />
        )}
      </nav>
    </Fragment>
  );
};

export default DefaultNavbar;
