import CustomButton from "@components/adaptors/CustomButton";
import StepProgressBar from "@components/StepProgressBar";
import { fas } from "@icons";
import { COURSES_ROUTE } from "@localization";
import { variants } from "@variants";
import { Col, Typography } from "antd";
import classNames from "classnames";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import classes from "./Navbar.module.scss";

const { Title } = Typography;

const JobCourseNavbar = () => {
  const router = useRouter();

  const jobCourse = useSelector((state) => state.courseDetails.course);
  const jobCourseProgress = useSelector((state) => state.courseProgress);

  return (
    <Col className={classNames(classes.courseNavbar, classes.jobCourseNavbar)}>
      <Col className={classes.lhs}>
        <CustomButton
          onClick={() => router.push(COURSES_ROUTE)}
          size="small"
          variant={variants.ghostButton}
          iconButton
          icon={fas.faArrowLeft}
        />
        <span className={classes.verticalDivider}></span>
        <Title className={classes.courseTitle}>{jobCourse?.title}</Title>
      </Col>
      <Col className={classes.rhs}>
        <StepProgressBar
          variant={variants.jobCourseProgressBar}
          percent={jobCourseProgress?.completionPercentage}
        />
      </Col>
    </Col>
  );
};

export default JobCourseNavbar;
