import { LockFilled, UnlockFilled } from "@ant-design/icons";
import { Col, Typography } from "antd";
import classNames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Fragment } from "react";
import { useSelector } from "react-redux";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";
import classes from "./StepProgressBar.module.scss";

const { Paragraph } = Typography;

const SkillCourseProgressBar = (props) => {
  const { percent } = props;
  const course = useSelector((state) => state.courseDetails.course);

  const lessonsCount = !isEmpty(course)
    ? course?.chapters[0]?.lessons.length
    : 0;

  const lessons = !isEmpty(course) ? course?.chapters[0]?.lessons : [];

  const lessonsCompleted = lessons.filter(
    (lesson) => lesson?.userLesson?.isCompleted
  ).length;

  return (
    <Fragment>
      <Col className={classes.stepProgressBarWrapper}>
        <Col className={classes.stepProgressBarArea}>
          <Col className={classes.chapters}>
            {lessonsCompleted} / {lessonsCount} Lessons ({percent}%)
          </Col>
          <Col className={classes.stepProgressBar}>
            <ProgressBar
              percent={percent}
              hasStepZero={false}
              filledBackground="#00B58A"
            >
              <Step transition="scale">
                {({ accomplished }) => (
                  <>
                    <Col
                      className={classNames(
                        classes.stepIndicator,
                        accomplished || percent === 100
                          ? classes.active
                          : classes.inactive
                      )}
                    >
                      {accomplished || percent === 100 ? (
                        <UnlockFilled />
                      ) : (
                        <LockFilled />
                      )}
                    </Col>
                    <Paragraph className={classes.stepName}>
                      Certificate
                    </Paragraph>
                  </>
                )}
              </Step>
            </ProgressBar>
          </Col>
        </Col>
      </Col>
    </Fragment>
  );
};

SkillCourseProgressBar.propTypes = {
  percent: PropTypes.number,
};

SkillCourseProgressBar.defaultProps = {
  percent: 0,
};

export default SkillCourseProgressBar;
